import React from "react";
import { formatCategoryName } from "../utils";

const TransactionsList = ({ transactions }) => (
  <div className="m-5">
    {transactions.map((transaction, index) => (
      <div
        key={index}
        className="bg-white shadow-md p-4 rounded-lg mb-4 flex"
        style={{ width: "100%" }}
      >
        <div className="w-full md:w-3/4 pr-4 md:pr-0 text-sm md:text-md">
          <p className="text-gray-600 font-medium">Transaction Name</p>
          <p>{transaction.name}</p>
          <p className="text-gray-600 font-medium">Transaction Date</p>
          <p>{transaction.date}</p>
          <p className="text-gray-600 font-medium">Transaction Category</p>
          <p>
            {formatCategoryName(transaction.personal_finance_category.primary)}
          </p>
        </div>
        <div className="w-full md:w-1/4 flex flex-col md:justify-start">
          <p className="text-gray-600 font-medium">Transaction Amount</p>
          <p className="text-lg md:text-3xl">
            ${transaction.amount.toFixed(2)}
          </p>
        </div>
      </div>
    ))}
  </div>
);

export default TransactionsList;
