import React from "react";
import dolphinLogo from "../images/DolphinLogo.jpg";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

function Header() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Logged out and navigating to home");
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error logging out:", error.message);
      });
  };

  return (
    <div className="flex items-center bg-[url('./images/background.png')] bg-cover bg-center pt-5 pb-5 mb-5 justify-between">
      <div className="flex align-middle">
        <img
          src={dolphinLogo}
          alt="Dolphin Logo"
          className="h-10 w-10 md:h-20 md:w-20 ml-4 md:ml-10"
        />
        <a
          className="text-xs md:text-3xl font-bold ml-2 md:ml-3 self-center"
          href="/"
        >
          Dolphin
        </a>
        <a
          href="/"
          className="text-xs md:text-xl px-0.5 font-bold ml-1 md:ml-2 md:px-1 self-center border-2 md:border-4 border-blue-800 text-blue-800"
        >
          Beta
        </a>
        <a
          className="self-center font-bold text-gray-500 ml-1 md:ml-3"
          href="/"
        >
          v0.2
        </a>
      </div>
      {user ? (
        <button
          className="block text-white bg-red-500 mr-2 px-2 md:px-3 py-2 rounded-xl font-bold hover:bg-red-600 transition duration-300 ease-in-out"
          onClick={handleLogout}
        >
          Log Out
        </button>
      ) : (
        <div className="flex space-x-3 mr-3 md:mr-5">
          <button
            onClick={() => navigate("/signup")}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded md:text-sm text-xs"
          >
            Sign Up
          </button>
          <button
            onClick={() => navigate("/login")}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded md:text-sm text-xs"
          >
            Login
          </button>
        </div>
      )}
    </div>
  );
}

export default Header;
