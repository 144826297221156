import React, { useState, useEffect, useContext } from "react";
import TransactionsList from "../components/TransactionsList";
import Header from "../components/Header";
import TransactionsContext from "../TransactionsContext";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { auth } from "../firebaseConfig";

function OverviewPage() {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { transactions, setTransactions } = useContext(TransactionsContext);
  const [accounts, setAccounts] = useState([]); // Stores list of account names

  useEffect(() => {
    const fetchAllTransactions = async () => {
      const userAccountsRef = collection(
        db,
        "users",
        auth.currentUser.uid,
        "accounts"
      );
      const userAccountsSnapshot = await getDocs(userAccountsRef);
      let allTransactions = [];
      let accountsData = [];

      userAccountsSnapshot.forEach((accountDoc) => {
        const accountData = accountDoc.data();
        const accountName = accountData.name;
        accountsData.push(accountName);

        // Append account name to each transaction
        const transactionsWithAccountName = accountData.transactions.map(
          (transaction) => ({
            ...transaction,
            accountName: accountName,
          })
        );
        allTransactions = allTransactions.concat(transactionsWithAccountName);
      });

      setAccounts(accountsData);
      setTransactions(allTransactions);
    };

    fetchAllTransactions();
  }, [setTransactions]);

  const handleAccountClick = (accountName) => {
    setSelectedAccount(accountName);
  };

  const handleAllTransactionsClick = () => {
    setSelectedAccount(null);
  };

  return (
    <div className="bg-white">
      <Header></Header>
      <div className="px-4 md:px-10">
        {transactions.length > 0 && (
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="w-full md:w-1/2 md:mb-0 mb-10">
              <div className="flex justify-center mb-5 p-3 md:p-5 shadow-inner rounded-lg bg-gray-100">
                <p className="w-1/2 text-center justify-center self-center text-lg font-bold">
                  April Spending
                </p>
                <select
                  className="w-1/2 bg-white border border-gray-300 rounded-md px-4 py-2 text-lg text-center"
                  onChange={(e) => {
                    if (e.target.value === "all") {
                      handleAllTransactionsClick();
                    } else {
                      handleAccountClick(e.target.value);
                    }
                  }}
                >
                  <option value="all">All Transactions</option>
                  {accounts.map((accountName) => (
                    <option key={accountName} value={accountName}>
                      {accountName}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="overflow-y-auto bg-gray-100"
                style={{ maxHeight: "70vh" }}
              >
                <TransactionsList
                  transactions={
                    selectedAccount
                      ? transactions.filter(
                          (transaction) =>
                            transaction.accountName === selectedAccount
                        )
                      : transactions
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OverviewPage;
