// utils.js

function formatCategoryName(category) {
  return category
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

const fetchTransactions = async (
  token,
  setTransactions,
  addAccountToFirebase = null,
  accountName = null,
  accessToken = null
) => {
  const response = await fetch(
    "https://dolphinmvp-8adc59ef82b1.herokuapp.com/get_transactions",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    }
  );
  const data = await response.json();

  // Set transactions in the context
  setTransactions((prevTransactions) => [
    ...prevTransactions,
    ...data.transactions,
  ]);

  // Add account and transactions to Firebase if provided
  if (addAccountToFirebase && accountName) {
    addAccountToFirebase(accountName, data.transactions, accessToken);
  }
};

const handleAuthClick = (setToken, setTransactions, addAccountToFirebase) => {
  fetch("https://dolphinmvp-8adc59ef82b1.herokuapp.com/create_link_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const linkToken = data.link_token;
      const linkHandler = window.Plaid.create({
        token: linkToken,
        onSuccess: function (publicToken, metadata) {
          fetch(
            "https://dolphinmvp-8adc59ef82b1.herokuapp.com/get_access_token",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ publicToken: publicToken }),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              const accessToken = data.accessToken;
              setToken(accessToken);
              // Fetch transactions and add account to Firebase
              fetchTransactions(
                accessToken,
                setTransactions,
                addAccountToFirebase,
                metadata.account.name,
                accessToken
              );
            });
        },
        onExit: function (err, metadata) {
          if (err) {
            console.error("Error:", err);
          }
        },
      });
      linkHandler.open();
    });
};

export { handleAuthClick, formatCategoryName, fetchTransactions };
