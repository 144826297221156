import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import OverviewPage from "./pages/OverviewPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import TokenContext from "./TokenContext";
import TransactionsContext from "./TransactionsContext";
import OnboardingPage from "./pages/OnboardingPage";
import "./index.css";
import { inject } from "@vercel/analytics";

function App() {
  const [token, setToken] = useState(null);
  const [transactions, setTransactions] = useState([]);
  inject();

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      <TransactionsContext.Provider value={{ transactions, setTransactions }}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={<OverviewPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/onboarding" element={<OnboardingPage />} />
          </Routes>
        </Router>
      </TransactionsContext.Provider>
    </TokenContext.Provider>
  );
}

export default App;
