import React from "react";
import DolphinLogo from "../images/DolphinLogoSVG.svg";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  return (
    <div className="bg-white flex flex-col items-center justify-center text-center h-screen px-5">
      <img
        src={DolphinLogo}
        alt="Dolphin logo"
        className="mx-auto mt-0 h-1/3"
      />
      <div className="md:w-1/2 w-full">
        <p className="md:text-8xl text-6xl font-semibold md:mb-7 mb-5">
          dolphin
        </p>
        <p className="md:text-xl text-lg md:mt-5 mt-2 text-gray-500">
          All your finance, all in one place. Dive in today
        </p>
      </div>
      <div className="md:w-1/2 w-full mt-10">
        <div className="w-full px-4 mt-5">
          <button
            onClick={() => navigate("/login")}
            className="bg-blue-700 hover:bg-blue-900 text-white border border-blue-700 font-bold py-5 rounded-xl w-full"
          >
            Sign In
          </button>
        </div>
        <div className="w-full px-4 mt-5">
          <button
            onClick={() => navigate("/signup")}
            className="bg-white hover:bg-gray-200 text-black border border-blue-700 font-bold py-5 rounded-xl w-full"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
