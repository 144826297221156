import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleAuthClick } from "../utils";
import TokenContext from "../TokenContext";
import TransactionsContext from "../TransactionsContext";
import DolphinLogo from "../images/DolphinLogoSVG.svg";
import { db } from "../firebaseConfig";
import { doc, setDoc, collection, onSnapshot } from "firebase/firestore";
import { auth } from "../firebaseConfig";

function OnboardingPage() {
  const [isCashAccountsOpen, setIsCashAccountsOpen] = useState(false);
  const [cashAccounts, setCashAccounts] = useState([]);
  const { setToken } = useContext(TokenContext);
  const { transactions, setTransactions } = useContext(TransactionsContext);
  const navigate = useNavigate();

  useEffect(() => {
    const accountsRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "accounts"
    );
    const unsubscribe = onSnapshot(accountsRef, (snapshot) => {
      const fetchedAccounts = [];
      snapshot.forEach((doc) => {
        fetchedAccounts.push(doc.data().name);
      });
      setCashAccounts(fetchedAccounts);
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const addAccountToFirebase = async (
    accountName,
    transactions,
    accessToken
  ) => {
    const accountRef = doc(
      collection(db, "users", auth.currentUser.uid, "accounts")
    );
    await setDoc(accountRef, {
      name: accountName,
      transactions: transactions,
      accessToken: accessToken, // Store the access token
    });
  };

  const handleLinkAccount = () => {
    handleAuthClick(setToken, setTransactions, addAccountToFirebase);
  };

  const toggleCashAccounts = () => {
    setIsCashAccountsOpen(!isCashAccountsOpen);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="bg-white p-5 text-center flex-1">
        <img
          src={DolphinLogo}
          alt="Dolphin logo"
          className="mx-auto my-4 w-32 h-32"
        />
        <h1 className="text-xl sm:text-3xl font-bold mt-2">
          Ready to dive into your finances?
        </h1>
        <p className="text-sm sm:text-base font-light mt-2 mb-4">
          Link all your accounts and view them in one place.
        </p>
        <div className="space-y-2">
          <div
            className="accordion-header p-4 border border-gray-200 rounded-lg shadow-sm hover:cursor-pointer"
            onClick={toggleCashAccounts}
          >
            <h2 className="flex justify-between items-center font-bold text-blue-700">
              Accounts
              <span className="text-lg">{isCashAccountsOpen ? "-" : "+"}</span>
            </h2>
          </div>
          {isCashAccountsOpen && (
            <div className="accordion-content p-4 border border-gray-200 rounded-lg shadow-sm">
              {cashAccounts.length > 0 ? (
                cashAccounts.map((account, index) => (
                  <p key={index}>{account}</p>
                ))
              ) : (
                <p>No accounts linked yet.</p>
              )}
            </div>
          )}
        </div>
        <p className="text-xs sm:text-sm text-gray-500 mt-4">
          We use Plaid to safely and securely connect your accounts.
        </p>
      </div>
      <div className="m-5 sticky bottom-0 md:flex md:flex-row md:space-x-2">
        <button
          onClick={handleLinkAccount}
          className="text-white w-full p-4 rounded-lg font-bold bg-blue-600 mb-2 md:mb-0"
        >
          Connect Account
        </button>
        {transactions.length && (
          <button
            onClick={() => {
              navigate("/dashboard");
            }}
            className="w-full p-4 rounded-lg font-bold bg-white border border-blue-700 text-black"
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

export default OnboardingPage;
